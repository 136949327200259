import { Component,OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {BreadcrumbService, Breadcrumb } from 'angular-crumbs';
import {Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
Title = "Angular Breadcrumb";
constructor(private titleService: Title, private breadcrumbService:BreadcrumbService){}


ngOnInit() {
  this.breadcrumbService.breadcrumbChanged.subscribe((crumbs) => {
    this.titleService.setTitle(this.createTitle(crumbs));
  });
}

private createTitle(routersCollection: Breadcrumb[]) {
const title = 'BMusic CMS';
const titles =  routersCollection.filter((route)=> route.displayName);

if(!titles.length){return title;}
const routeTitle = this.titleToString(titles);
return `${routeTitle} ${title}`;
}

private titleToString(titles){
return titles.reduce((prev, curr)=>{
	return `${curr.displayName} - ${prev}`;
   }, '');
}

getAnimationData(outlet:RouterOutlet){
	return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
}


}
