import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'privacy_policy', loadChildren: './privacy_policy/privacy_policy.module#PrivacyPolicyModule',data: { breadcrumb: 'privacy_policy'} },
  { path: 'term_and_condition', loadChildren: './term_and_condition/term_and_condition.module#TermAndConditionModule',data: { breadcrumb: 'term_and_condition'} },
  { path: '', loadChildren: './login/login.module#LoginModule',data: { breadcrumb: 'login'} },
   { path: 'admin', loadChildren: './dashboard/dashboard.module#DashboardModule', data: { breadcrumb: 'Dashboard'} }
    
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
  providers: []
})
export class AppRoutingModule { }
